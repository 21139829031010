import React from "react";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";

import Footer from "../../layouts/footer";

import ScrollToTop from "../../components/scroll-to-top";
import { Link } from "react-router-dom";

const Brimstone = () => {
  return (
    <React.Fragment>
            <Layout>
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal ">
                        <div className="d-wl">  

                        <div className="d-w1">

<div className="brim-logo-div">
    <div>

    <img 
     className="brim-ss"
     src={require('./brimstoneimg/Logo.png')} alt="" />
     </div>
     <div>
        <h1 className="hea-ci">
            Brim-gh
        </h1>
     </div>
     <div>
        <h1>

        </h1>
     </div>
</div>
<div className="bb-top-d">
    <div className="pro-brim">
        <img
        className="bb-pic"
        
        src={require('./brimstoneimg/1.jpg')} alt="" />

    </div>
    <div className="pro-brim">
        <img 
        className="bb-pic"
        src={require('./brimstoneimg/2.jpg')} alt="" />

    </div>
</div>
<div>

</div>
<div className="dd-io">

<a target="_blank" href="https://brimstonelabs.com/">
    <button className="bb-none1">
    Brimstone Labs

    </button>
</a>
</div>
</div>


</div>

                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
  )
}

export default Brimstone