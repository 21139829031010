import SectionTitle from "../../../components/section-title";
import { useHistory } from "react-router-dom";

const TeamContainer = () => {
    const history = useHistory();

    return (
        <div className="team-area team-default-area bg-gray">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            title="<span>
                            PRODUCT</span> RANGE"
                        />
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-duration="1300"
                    >
                        <p
                        style={{textAlign:'center'}}
                        >
                        At our core, we provide an unparalleled range of premium products that empower you to optimize your performance and elevate your lifestyle.
                        </p>

                        <div className="bb-b2">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => history.push("/Injectable")}
                                className="head-pro4"
                            >
                                <div>
                                    <span className="head-pros">
                                        INJECTABLE
                                    </span>
                                </div>
                                <div className="pro-imggd">
                                    <img
                                        src={require("../../../assets/img/injectable.jpg")}
                                        alt=""
                                    />
                                </div>
                               
                            </div>
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => history.push("/Oral")}
                                className="head-pro4"
                            >
                                <div>
                                    <span className="head-pros">Oral</span>
                                </div>
                                <div className="pro-imggd">
                                    <img
                                        src={require("../../../assets/img/oral.jpg")}
                                        alt=""
                                    />
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamContainer;
