import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { getStorage } from "firebase/storage";


const app = firebase.initializeApp({
    apiKey: "AIzaSyCFn7gMx9I3TPowyu7zbrABuhRloT6tUss",
    authDomain: "wellnesspharmaceuticals-d4702.firebaseapp.com",
    projectId: "wellnesspharmaceuticals-d4702",
    storageBucket: "wellnesspharmaceuticals-d4702.appspot.com",
    messagingSenderId: "896136654512",
    appId: "1:896136654512:web:737bc015922d156b551575"
});

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = app.auth()
export default app

