import React from "react";
import AboutData from "../../data/about.json";

import { Link } from "react-router-dom";
import { flatDeep, slugify, containsObject } from "../../utils";


const AboutContainer = () => {
 

    return (
        <div className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <p className="text-pra" data-aos="fade-up">
                            {AboutData[0].pageTitle}
                        </p>
                        <div
                            className="service-list-content"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h4 className="title">{AboutData[1].title}</h4>
                            {AboutData[1].excerpt.map((single, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="desc"
                                        dangerouslySetInnerHTML={{
                                            __html: single,
                                        }}
                                    />
                                );
                            })}
                      
                      
                        </div>
                        <div
                            className="office-center-content"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <h4 className="title">{AboutData[2].title}</h4>
                         
                
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
