import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/Home";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/css/icofont.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";

import "./assets/css/animate.css";
// import "lightgallery.js/dist/css/lightgallery.css";
// import "swiper/components/pagination/pagination.scss";
import AboutPage from "./pages/about";

import toast, { Toaster } from "react-hot-toast";



import ContactPage from "./pages/contact";
import NavScrollTop from "./components/nav-scroll-top";
import Products1 from "./pages/Products/Products1";
import Products2 from "./pages/Products/Products2";
import Brimstone from "./pages/Brimstone/Brimstone";
import Authenticity from "./pages/Authenticity/Authenticity";

const App = () => {
    useEffect(() => {
        AOS.init({
            offset: 80,
            duration: 1000,
            once: true,
            easing: "ease",
        });
        AOS.refresh();
    }, []);
    return (
        <Router>
            <NavScrollTop>
                <Toaster />

                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL + "/"}`}
                        exact
                        component={HomePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/Company-Profile"}`}
                        component={AboutPage}
                    />

                    <Route
                        path={`${process.env.PUBLIC_URL + "/Injectable"}`}
                        component={Products1}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/Oral"}`}
                        component={Products2}
                    />
                    {/* <Route
                        path={`${
                            process.env.PUBLIC_URL + "/service-details/:id"
                        }`}
                        component={ServiceDetails}
                    /> */}
               

                    <Route
                        path={`${process.env.PUBLIC_URL + "/Contact"}`}
                        component={ContactPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/Brimstone"}`}
                        component={Brimstone}
                    />
                         <Route
                        path={`${process.env.PUBLIC_URL + "/check-authenticity"}`}
                        component={Authenticity}
                    />
                </Switch>
            </NavScrollTop>
        </Router>
    );
};

export default App;
